import { useEffect } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

import { LOGIN_SEO_DESCRIPTION, LOGO_NAME, SOURCE_STORAGE_KEY } from '@/constants';
import styles from '@/styles/Onboarding.module.css';

const Seo = dynamic(() => import('@/head/Seo'));
const LoginSection = dynamic(() => import('@/components/LoginSection'));
const LoginRightSection = dynamic(() => import('@/components/LoginRightSection'));

function Login() {
  const router = useRouter();

  useEffect(() => {
    localStorage?.setItem(SOURCE_STORAGE_KEY, router?.query?.utm_source as string);
  }, [router?.query?.utm_source]);

  return (
    <div className={styles.container}>
      <Seo title={LOGO_NAME} desc={LOGIN_SEO_DESCRIPTION} />
      <div className={styles.signupContainer}>
        <div className={styles.leftContainer}>
          <LoginSection />
        </div>
        <div className={styles.rightContainer}>
          <LoginRightSection />
        </div>
      </div>
    </div>
  );
}

Login.theme = 'light';
export default Login;
